import * as yup from 'yup';

export const patientValidationSchma = {
  name: yup
    .string()
    .min(4, 'Too Short Name!')
    .max(50, 'Too Long Name!')
    .required('Fullname is required!'),
  fileID: yup.string().required('FileId is required!'),
  birthdate: yup.string().required('Birthdate is required!'),
  gender: yup.string().required('Gender is required!')
};

export const goalsValidationSchma = {
  title: yup.string().required('Title is required!'),
  goals: yup.string().required('Goals is required!')
};
