import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { useAppContext } from 'providers/AppProvider';
import { TooltipComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([TooltipComponent, PieChart, CanvasRenderer]);

interface NutritionFactInterFace {
  value: any;
  nutrientName: string;
  unitName: string;
}
interface NutrientInterFace {
  nutritionFact: Array<NutritionFactInterFace>;
  quantity: string;
  name: string;
  servingSize: number;
  servingSizeUnit: string;
}

interface TotalNutrients {
  [key: string]: {
    value: number;
    unitName: string;
  };
}

const getDefaultOptions = (
  getThemeColor: (name: string) => string,
  chartData: any
) => ({
  color: [
    getThemeColor('info-light'),
    getThemeColor('warning-light'),
    getThemeColor('danger-light'),
    getThemeColor('success-light'),
    getThemeColor('primary')
  ],
  tooltip: {
    trigger: 'item',
    formatter: '{b}: {d}%'
  },
  responsive: true,
  maintainAspectRatio: false,

  series: [
    {
      name: '',
      type: 'pie',
      radius: ['0%', '90%'],
      startAngle: 30,
      avoidLabelOverlap: true,

      label: {
        show: false,
        position: 'center',
        formatter: '',
        rich: {
          x: {
            fontSize: 31.25,
            fontWeight: 800,
            color: getThemeColor('tertiary-color'),
            padding: [0, 0, 5, 15]
          },
          y: {
            fontSize: 12.8,
            color: getThemeColor('tertiary-color'),
            fontWeight: 600
          }
        }
      },
      emphasis: {
        label: {
          show: false
        }
      },
      labelLine: {
        show: false
      },
      data: chartData
    }
  ],
  grid: {
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    containLabel: false
  }
});
const getFormatedNutrient = (nutrient: Array<NutritionFactInterFace>) => {
  const totalNutrients: TotalNutrients = {};

  // Loop through each food item
  nutrient.forEach((item: any) => {
    item.nutritionFact.forEach((nutrient: NutritionFactInterFace) => {
      const { nutrientName, value, unitName } = nutrient;
      if (nutrientName !== 'Energy') {
        if (!totalNutrients[nutrientName]) {
          totalNutrients[nutrientName] = { value: 0, unitName };
        }
        totalNutrients[nutrientName].value += value;
      }
    });
  });

  // Convert the result to an array of objects
  const totalNutritionArray = Object.keys(totalNutrients).map(nutrientName => ({
    value: totalNutrients[nutrientName].value,
    name: nutrientName
  }));
  return totalNutritionArray;
};
const FoodPaichart = (props: any) => {
  const { getThemeColor } = useAppContext();
  const chartData = getFormatedNutrient(props.nutrient);
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getDefaultOptions(getThemeColor, chartData)}
      style={{ minHeight: '320px', width: '100%' }}
    />
  );
};

export default FoodPaichart;
